
import Vue from 'vue'
import gsap from 'gsap';
import playWhenInView from '@/modules/@common/animations/play-when-in-view';

const mainTimeline = gsap.timeline();

export default Vue.extend({
  name: 'TopLeftUi',
  mounted() {
    mainTimeline.add(this.animateUi(),0);
    playWhenInView(mainTimeline,`#${this.uniqueId}`)
  },
  props: {
    uniqueId:{
      type: String,
      default: 'top-left-ui',
    }
  },
  beforeDestroy() {
    mainTimeline.kill();
  },
  methods: {
    animateUi() {
      return gsap.timeline({ repeat: -1 })
      .add(gsap.timeline({ repeat: -1 })
        .to(`#${this.uniqueId} #cursor`, { x: 0, y: 0, duration: 1, ease: "power1.out" })
        .to(`#${this.uniqueId} #cursor`, { rotate: -10, scale: 0.9, duration: 0.2, ease: "power1.out" })
        .to(`#${this.uniqueId} #cursor`, { rotate: 0, scale: 1, duration: 0.2, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #checkbox`, { fill: "#066fef", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { y: 34, duration: 0.8, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #cursor`, { rotate: -10, scale: 0.9, duration: 0.2, ease: "power1.out" })
        .to(`#${this.uniqueId} #cursor`, { rotate: 0, scale: 1, duration: 0.2, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #checkbox-2`, { fill: "#066fef", duration: 0.2, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #modal`, { opacity: 1, duration: 0.4, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #cursor`, { x: 190, y: -124, duration: 1, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #cursor`, { rotate: -10, scale: 0.9, duration: 0.2, ease: "power1.out" })
        .to(`#${this.uniqueId} #cursor`, { rotate: 0, scale: 1, duration: 0.2, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #switch-circle`, { x: 0, fill: "#47B52B", duration: 0.3, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #switch-bg`, { fill: "#ABE79C", duration: 0.3, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { y: -94, duration: 0.8, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #cursor`, { rotate: -10, scale: 0.9, duration: 0.2, ease: "power1.out" })
        .to(`#${this.uniqueId} #cursor`, { rotate: 0, scale: 1, duration: 0.2, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #switch-2-circle`, { x: 0, fill: "#47B52B", duration: 0.3, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #switch-2-bg`, { fill: "#ABE79C", duration: 0.3, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { x: 280, y: 0, duration: 1, delay: 0.8, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #modal`, { opacity: 0, duration: 0.4, ease: "power1.inOut" })
        .to(`#${this.uniqueId} #checkbox`, { fill: "#ffffff", duration: 0.4, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #checkbox-2`, { fill: "#ffffff", duration: 0.4, ease: "power1.out" }, "<")
        .to(`#${this.uniqueId} #cursor`, { x: 288, duration: 10, ease: "power1.inOut" })
        .duration(18)
      , 0);
    }
  }
})
